const Home = (props) => {

    const count = 200,
        defaults = {
            origin: { y: 0.7 },
        };

    function fire(particleRatio, opts) {
        window.confetti(
            Object.assign({}, defaults, opts, {
                particleCount: Math.floor(count * particleRatio),
                scalar: 2.4,
                shapes: ["image"],
                shapeOptions: {
                    image: [
                        {
                            src: "/sonnyface.png",
                            width: 180,
                            height: 180,
                        },
                    ],
                },
            })
        );
    }

    const startConfetti = () => {
        // fire(0.25, {
        //     spread: 26,
        //     startVelocity: 55,
        // });

        // fire(0.2, {
        //     spread: 60,
        // });

        // fire(0.35, {
        //     spread: 100,
        //     decay: 0.91,
        //     scalar: 0.8,
        // });

        // fire(0.1, {
        //     spread: 120,
        //     startVelocity: 25,
        //     decay: 0.92,
        //     scalar: 1.2,
        // });

        // fire(0.1, {
        //     spread: 120,
        //     startVelocity: 45,
        // });

        const defaults = {
            spread: 360,
            ticks: 100,
            gravity: 0,
            decay: 0.94,
            startVelocity: 30,
            shapes: ["image"],
            shapeOptions: {
                image: [
                    {
                        src: "/sonnyface.png",
                        width: 180,
                        height: 180,
                    },
                ],
            },
        };

        window.confetti({
            ...defaults,
            particleCount: 30,
            scalar: 2,
        });

        window.confetti({
            ...defaults,
            particleCount: 20,
            scalar: 3,
        });

        window.confetti({
            ...defaults,
            particleCount: 15,
            scalar: 4,
        });

        window.confetti({
            ...defaults,
            particleCount: 10,
            scalar: 5,
        });

        window.confetti({
            ...defaults,
            particleCount: 5,
            scalar: 6,
        });
    }

    return (
        <div className='mainContainer'>
            <div className='main'>
                <div className='center'>
                    <div className='animate__animated animate__fadeIn'>
                        <button onClick={startConfetti}>
                            <img src='/gloria.jpg' style={{ marginTop: '0px', width: '224px', borderRadius: '24px' }} />
                        </button>
                    </div>
                    <div style={{ padding: '40px 0px 0px', textAlign: 'center' }}>
                        <a target='blank' href='https://www.instagram.com/gloriajungmee' style={{ maxWidth: '40px' }}>
                            <svg className="icon" viewBox="0 0 512 512"><path d="M256 49.5c67.3 0 75.2 0.3 101.8 1.5 24.6 1.1 37.9 5.2 46.8 8.7 11.8 4.6 20.2 10 29 18.8 8.8 8.8 14.3 17.2 18.8 29 3.4 8.9 7.6 22.2 8.7 46.8 1.2 26.6 1.5 34.5 1.5 101.8s-0.3 75.2-1.5 101.8c-1.1 24.6-5.2 37.9-8.7 46.8 -4.6 11.8-10 20.2-18.8 29 -8.8 8.8-17.2 14.3-29 18.8 -8.9 3.4-22.2 7.6-46.8 8.7 -26.6 1.2-34.5 1.5-101.8 1.5s-75.2-0.3-101.8-1.5c-24.6-1.1-37.9-5.2-46.8-8.7 -11.8-4.6-20.2-10-29-18.8 -8.8-8.8-14.3-17.2-18.8-29 -3.4-8.9-7.6-22.2-8.7-46.8 -1.2-26.6-1.5-34.5-1.5-101.8s0.3-75.2 1.5-101.8c1.1-24.6 5.2-37.9 8.7-46.8 4.6-11.8 10-20.2 18.8-29 8.8-8.8 17.2-14.3 29-18.8 8.9-3.4 22.2-7.6 46.8-8.7C180.8 49.7 188.7 49.5 256 49.5M256 4.1c-68.4 0-77 0.3-103.9 1.5C125.3 6.8 107 11.1 91 17.3c-16.6 6.4-30.6 15.1-44.6 29.1 -14 14-22.6 28.1-29.1 44.6 -6.2 16-10.5 34.3-11.7 61.2C4.4 179 4.1 187.6 4.1 256c0 68.4 0.3 77 1.5 103.9 1.2 26.8 5.5 45.1 11.7 61.2 6.4 16.6 15.1 30.6 29.1 44.6 14 14 28.1 22.6 44.6 29.1 16 6.2 34.3 10.5 61.2 11.7 26.9 1.2 35.4 1.5 103.9 1.5s77-0.3 103.9-1.5c26.8-1.2 45.1-5.5 61.2-11.7 16.6-6.4 30.6-15.1 44.6-29.1 14-14 22.6-28.1 29.1-44.6 6.2-16 10.5-34.3 11.7-61.2 1.2-26.9 1.5-35.4 1.5-103.9s-0.3-77-1.5-103.9c-1.2-26.8-5.5-45.1-11.7-61.2 -6.4-16.6-15.1-30.6-29.1-44.6 -14-14-28.1-22.6-44.6-29.1 -16-6.2-34.3-10.5-61.2-11.7C333 4.4 324.4 4.1 256 4.1L256 4.1z"></path><path d="M256 126.6c-71.4 0-129.4 57.9-129.4 129.4S184.6 385.4 256 385.4 385.4 327.4 385.4 256 327.4 126.6 256 126.6zM256 340c-46.4 0-84-37.6-84-84s37.6-84 84-84c46.4 0 84 37.6 84 84S302.4 340 256 340z"></path><circle cx="390.5" cy="121.5" r="30.2"></circle></svg>
                        </a>
                        <a target='blank' href='https://www.linkedin.com/in/gloriajhwang'>
                            <svg className="icon" viewBox="0 0 36 36"><path d="M33.2 0H2.5C1 0-0.2 1.2-0.2 2.6v30.8c0 1.4 1.2 2.6 2.7 2.6h30.7c1.5 0 2.7-1.2 2.7-2.6V2.6C35.8 1.2 34.6 0 33.2 0zM10.5 30.7H5.2V13.5h5.3V30.7zM7.8 11.1c-1.7 0-3.1-1.4-3.1-3.1C4.8 6.3 6.1 5 7.8 5c1.7 0 3.1 1.4 3.1 3.1C10.9 9.8 9.6 11.1 7.8 11.1zM30.5 30.7h-5.3v-8.4c0-2 0-4.6-2.8-4.6 -2.8 0-3.2 2.2-3.2 4.4v8.5h-5.3V13.5H19v2.3h0.1c0.7-1.4 2.5-2.8 5.1-2.8 5.4 0 6.4 3.6 6.4 8.2V30.7z"></path></svg>
                        </a>
                    </div>
                </div >
            </div>
        </div>
    );
};

export default Home;